<template>
  <div class="loginPage">
    <div class="bg">
      <div class="content" v-if="!showTestCode">
        <p>手机号码登录</p>
        <Form>
          <Field
            v-model.trim="phone"
            name="手机号"
            placeholder="输入手机号"
            :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <Button v-if="isPhone" color="#2b72ff" block @click="getNewTestCode"
            >获取手机验证码</Button
          >
          <Button v-else color="#dfdfdf" block @click="testPhone"
            >获取手机验证码</Button
          >
        </Form>
      </div>
      <div class="content" v-else>
        <p>验证码</p>
        <Form>
          <PasswordInput
            v-model.trim="testCode"
            :mask="false"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
          />
          <number-keyboard
            :show="showKeyboard"
            @input="onInput"
            @delete="onDelete"
            @blur="showKeyboard = false"
          />
          <Button
            v-if="second == 0"
            color="#2b72ff"
            block
            @click="getNewTestCode"
            >重新获取验证码</Button
          >
          <Button v-else color="#2b72ff" plain block
            >已发送验证码（{{ second }}s）</Button
          >
        </Form>
      </div>
    </div>
    <div class="radius"></div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Notify,
  PasswordInput,
  NumberKeyboard,
  Toast,
} from "vant";
import { getVerifiCode, login ,getUserInfo} from "@/api/my.js";
import {setCookie,getCookie} from "@/utils/util.js"

export default {
  name: "login",
  components: {
    Button,
    Form,
    Field,
    PasswordInput,
    NumberKeyboard,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      phone: "",
      isPhone: false,
      showTestCode: false,
      testCode: "",
      showKeyboard: true,
      getTestCode: "", //根据手机号获取到的验证码
      second: 60,
      timer: null,
      from: "", //从我的进来的
      meetId: "", //扫码携带的参数
    };
  },
  created() {
    this.from = this.$route.query.from || "";
    this.meetId = this.$route.query.id || "";
    //this.getOpenId();
  },
  mounted(){
    let clientId=localStorage.getItem("clientId")
    let clientId2=getCookie("clientId")

    if(clientId || clientId2){
      let id=clientId||clientId2;
      getUserInfo({clientId:id}).then(res=>{
                    if(res.status=="S"){
                        if(res.data){
                        if (this.from == "my") {
                          this.$router.push("/my");
                        } else if (this.meetId) {
                          this.$router.push("/meetDetail?id=" + this.meetId);
                        } else {
                          this.$router.push("/meetList");
                        }
                        }else{
                            localStorage.setItem('clientId','');
                            setCookie("clientId", '')
                        }
                    }else{
                        localStorage.setItem('clientId','');
                        setCookie("clientId", '')
                    }
                })

    }
  },

  methods: {
    getOpenId() {
      let openId = "123456";
      if (openId) {
        if (this.from == "my") {
          this.$router.push("/my");
        } else if (this.meetId) {
          this.$router.push("/meetDetail?id=" + this.meetId);
        } else {
          this.$router.push("/meetList");
        }
      }
    },
    //验证手机号码
    testPhone() {
      var pattern = /^(13|14|15|17|18|19|16)[0-9]{9}$/;
      let bool = pattern.test(this.phone);

      if (!bool) {
        Notify({
          color: "#fff",
          background: "rgba(0,0,0,0.50)",
          message: "请输入正确格式的手机号码",
        });
      }
    },
    getNewTestCode() {
      this.showTestCode = true;
      getVerifiCode({ mobile: this.phone }).then((res) => {
        if (res.status == "S") {
          Toast("验证码发送成功！");
          //倒计时
          this.second = 60;
          this.timer = setInterval(() => {
            if (this.second > 0) {
              this.second = this.second - 1;
            } else {
              this.timer = null;
              clearInterval(this.timer);
            }
          }, 1000);
          //以后删除掉验证码
          //this.testCode = res.data;
        }
      });
    },
    //键盘事件
    onInput(key) {
      this.testCode = (this.testCode + key).slice(0, 6);
    },
    onDelete() {
      this.testCode = this.testCode.slice(0, this.testCode.length - 1);
    },
    //登录
    login(val) {
      let data = { mobile: this.phone, openId: "", verfiCode: val };
      login(data).then((res) => {
        if (res.status == "S") {
          localStorage.setItem("clientId", res.data);
          setCookie("clientId", res.data)
          if (this.from == "my") {
            this.$router.push("/my");
          } else if (this.meetId) {
            this.$router.push("/meetDetail?id=" + this.meetId);
          } else {
            this.$router.push("/meetList");
          }
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    phone(val) {
      var pattern = /^(13|14|15|17|18|19|16)[0-9]{9}$/;
      this.isPhone = pattern.test(val);
    },
    testCode(val) {
      if (val.length == 6) {
        this.login(val);

        // if (val == this.getTestCode) {
        //   this.showKeyboard = false;
        //   this.login();
        // } else {
        //   Notify({
        //     color: "#fff",
        //     background: "rgba(0,0,0,0.50)",
        //     message: "验证码错误",
        //   });
        // }
      }
    },
    second(val) {
      if (val < 1) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-button {
  border-radius: 5px;
  height: 35 * 1.25px;
}
::v-deep.van-field {
  border: 1px solid #999;
  border-radius: 5px;
  margin: 20px 0 30px;
  height: 37.5px;
  padding: 0;
  line-height: 37.5px;
  input {
    text-align: center;
  }
}
::v-deep.van-password-input__security {
  padding-bottom: 40px;
  li {
    background: #f5f5f5;
    border: 1px solid #999999;
    border-radius: 4px;
    width: 70 * 1.25px;
    height: 32px;
    margin: 20px 0 0;
  }
}
.loginPage {
  .bg {
    height: 220 * 1.25px;
    background: url("../assets/loginBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 55px 12px 0;
    position: relative;
    box-sizing: border-box;
    .content {
      position: absolute;
      z-index: 100;
      width: calc(100% - 24px);;
      height: 195 * 1.25px;
      top: 55px;
      left: 12px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
      padding: 30px 25px 25px;
      box-sizing: border-box;
      p {
        font-size: 22px;
        color: #2c3445;
        margin: 0;
        padding: 0;
      }
    }
  }
  .radius {
    width: 100vw;
    height: 100px;
    background: #fff;
    border-radius: 100%;
    margin-top: -50px;
    position: relative;
  }
}
</style>
